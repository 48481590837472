<template>
  <div id=“app”>
    <header>
      <div>
        <!-- <label for="search-engine">Search Engine:</label> -->
        <form @submit.prevent="search">
          <input type="text" v-model="searchTerm" />
          <button :disabled="!searchTerm">Search</button>
        <select id="search-engine" v-model="searchEngine" @change="saveSearchEngine">
          <option value="google">Google</option>
          <option value="bing">Bing</option>
          <option value="fsou">Fsou</option>
          <option value="G">G镜像</option>
          <option value="B">B</option>
          <option value="GitHub">gitHub</option>
          <option value="Wiki">wiki</option>
          <option value="Greasyfork">greasyfork</option>
        </select>
        </form>
      </div>
    </header>
    <main>
      <router-view></router-view>
      <div class="links">
        <h2>Links:</h2>
        <ul>
          <li v-for="(link, index) in links" :key="link.id">
            <a :href="link.url" target="_blank">{{ link.name }}</a>
            <!-- <button @click="deleteLink(index)">Delete</button> -->
          </li>
        </ul>
      </div>
    </main>
      <mirror-sites></mirror-sites>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import MirrorSites from './components/MirrorSites.vue'

export default {
  name: 'App',
    components: {
    MirrorSites
  },
  setup() {
    const searchTerm = ref('')
    const searchEngine = ref(localStorage.getItem('searchEngine') || 'bing')
    const newLinkId = ref(5) // 由于我们默认会有3个链接，所以将newLinkId设置为4

    const searchEngineLink = computed(() => {
      switch (searchEngine.value) {
        case 'bing':
          return 'https://www.bing.com/search?q='
        case 'fsou':
          return 'https://fsoufsou.com/search?q='
        default:
          return 'https://www.google.com/search?q='
          case 'G' :
          return 'https://search.vwo50.me/search?q='
          case 'B' :
          return 'https://search.bilibili.com/all?keyword='
          case 'GitHub' :
          return 'https://github.com/search?q='
          case 'Wiki' :
          return 'https://zh.wikipedia.org/w/index.php?search='
          case 'greasyfork' :
          return 'https://greasyfork.org/zh-CN/scripts?q='
      }
    })

    const search = () => {
      if (searchTerm.value) {
        window.open(searchEngineLink.value + searchTerm.value, '_blank')
      }
    }

    const saveSearchEngine = () => {
      localStorage.setItem('searchEngine', searchEngine.value)
    }

    onMounted(() => {
      localStorage.setItem('searchEngine', searchEngine.value)
    })

    const links = ref(JSON.parse(localStorage.getItem('links')) || [
      { id: 1, name: 'GitHub', url: 'https://github.com/' },
      { id: 2, name: 'Google 谷歌镜像网站', url: 'https://c.aalib.net/tool/gglink/' },
      { id: 3, name: 'chatgpt', url: 'https://ai.seayouth.xyz/ai/chatgpt' },
      { id: 4, name: 'miui', url: 'https://miuiver.com/' },
      { id: 5, name: 'yspan', url: 'http://hipye.ysepan.com/' },
      { id: 6, name: 'axutongxue', url: 'https://axutongxue.com/' },
      { id: 7, name: 'excel表格公式', url: 'https://formulabot.com/' },
      { id: 8, name: 'chatgpt2', url: 'https://chat.extkj.cn/' },
      { id: 9, name: '维基百科镜像', url: 'https://cjh0613.com/WikipediaMirror' },
      { id: 10, name: '基岩服务器', url: 'https://www.powernukkitx.com/' }
    ])

    return {
      searchTerm,
      searchEngine,
      searchEngineLink,
      search,
      saveSearchEngine,
      links
    }
  }
}
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.links {
  margin-top: 20px;
}
</style>