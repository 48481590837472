<template>
  <div id="mirr">
    <h3>镜像站列表：</h3>
    <ul>
      <li v-for="(site, index) in mirrorSites" :key="index">
        {{ site.url }} | 
        <span :class="{'success': site.status === 'connected', 'failed': site.status === 'failed'}">
          {{ site.status === 'connected' ? '连接成功' : site.status === 'failed' ? '连接失败' : '正在连接' }}
        </span> |
        {{ site.connectTime > 0 ? `${site.connectTime}ms` : '' }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mirrorSites: [
        { url: 'https://search.ecnu.cf', status: 'connecting', connectTime: 0 },
        { url: 'https://search.vwo50.me', status: 'connecting', connectTime: 0 },
        { url: 'https://search.ahau.cf', status: 'connecting', connectTime: 0 },
        { url: 'https://g.damfu.com', status: 'connecting', connectTime: 0 },
        { url: 'https://googlehnzyc.azurewebsites.net', status: 'connecting', connectTime: 0 },
        { url: 'https://zh-two.iwiki.icu', status: 'connecting', connectTime: 0 }
      ]
    }
  },
  mounted() {
    const promises = this.mirrorSites.map(site => this.ping(site.url))
    Promise.all(promises)
      .then(res => {
        for (let i = 0; i < res.length; i++) {
          const site = this.mirrorSites[i]
          if (res[i]) {
            site.status = 'connected'
            site.connectTime = res[i]
          } else {
            site.status = 'failed'
          }
        }
      })
      .catch(err => {
        console.error(`Ping failed: ${err}`)
      })
  },
  methods: {
    ping(url) {
      return new Promise((resolve, reject) => {
        const startTime = new Date().getTime()
        fetch(url, {mode: 'no-cors'})
          .then(() => resolve(new Date().getTime() - startTime))
          .catch(() => resolve(false))
      })
    }
  }
}
</script>

<style>
.success {
  color: green;
  font-weight: bold;
}
.failed {
  color: red;
  font-weight: bold;
}
</style>